import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider2 from "../components/slider2"
import { getAddresses } from "../store/actions/addressActions"
import { getPaymentMethods } from "../store/actions/paymentMethodActions"
import { storeOrder } from "../store/actions/orderActions"
import { emptyCart } from "../store/actions/productActions"
import { loadUser } from "../store/actions/authActions"
import { BASE_URL } from "../utils/urls"

const Checkout = ({
  getAddresses,
  getPaymentMethods,
  isAuthenticated,
  cartProducts,
  shippingAmount,
  subTotalAmount,
  addresses,
  paymentMethods,
  storeOrder,
  emptyCart,
  errors,
  loadUser,
}) => {
  const [address, setAddress] = useState("")
  const [addressType, setAddressType] = useState("")
  const [paymentMethod, setPaymentMethod] = useState("")
  const [paymentMethodChild, setPaymentMethodChild] = useState("")
  const [paymentRef, setPaymentRef] = useState("")

  useEffect(() => {
    if (isAuthenticated !== true) {
      loadUser()
        .then(() => {
          getAddresses().then(() => {
            getPaymentMethods()
          })
        })
        .catch(() => {
          navigate("/login")
        })
    } else {
      getAddresses().then(() => {
        getPaymentMethods()
      })
    }
  }, [])

  const handlePaymentMethod = paymentMethod => {
    setPaymentMethod(paymentMethod)
    if (!paymentMethod.children) {
      setPaymentMethodChild("")
    }
  }

  const handleStoreOrder = () => {
    let order = {
      products: cartProducts,
      sub_total_price: subTotalAmount,
      shipping_price: shippingAmount,
      total_price: subTotalAmount + shippingAmount,
      payment_method_id:
        paymentMethodChild === "" ? paymentMethod.id : paymentMethodChild.id,
      payment_ref: paymentRef ? paymentRef : "",
      address_id: addressType === "new-address" ? "" : address.id,
      new_address: addressType === "new-address" ? address : "",
    }
    storeOrder(order)
      .then(() => {
        toast.success(
          "Commande enregistré 🚀. Nous vous contacterons très bientôt pour récuperer vos vêtements.",
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
        navigate("/orders")
        emptyCart()
      })
      .catch(() => console.log("error"))
  }

  const pageName = "COMMANDER"
  return (
    <Layout>
      <SEO title={pageName} />
      <Slider2 title={pageName} />
      <div className="flex flex-wrap container mx-auto py-6">
        <div className="text-xl py-2">Adresse de livraison</div>
        <div className="w-full text-sm">
          <span className="text-green-600 italic text-xs">
            {errors && errors.address_id ? errors.address_id : ""}
          </span>
          <div className="py-1 text-center">
            <button
              onClick={() => {
                setAddressType("new-address")
                setAddress("")
              }}
              className={
                addressType === "new-address"
                  ? "flex bg-blue-500 border-blue-500 hover:bg-blue-600 hover:border-blue-600 text-white py-2 px-2 rounded w-full"
                  : "flex bg-gray-100 border-gray-100 hover:bg-gray-300 hover:border-gray-300 py-2 px-2 rounded w-full"
              }
            >
              {addressType === "new-address" ? (
                <svg
                  version="1.1"
                  id="Circled_minus"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 20 20"
                  enableBackground="new 0 0 20 20"
                  className="h-6 w-6 fill-current mx-1"
                >
                  <path
                    d="M10,1.6c-4.639,0-8.4,3.761-8.4,8.4c0,4.639,3.761,8.4,8.4,8.4s8.4-3.761,8.4-8.4C18.4,5.361,14.639,1.6,10,1.6z
                  M14.789,13.061l-1.729,1.729L10,11.729l-3.061,3.06l-1.729-1.729L8.272,10L5.211,6.939L6.94,5.211L10,8.271l3.061-3.061
                 l1.729,1.729L11.728,10L14.789,13.061z"
                  />
                </svg>
              ) : (
                <svg
                  version="1.1"
                  id="Circle"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 20 20"
                  enableBackground="new 0 0 20 20"
                  className="h-6 w-6 fill-current mx-1"
                >
                  <path
                    d="M10,0.4C4.698,0.4,0.4,4.698,0.4,10C0.4,15.302,4.698,19.6,10,19.6c5.301,0,9.6-4.298,9.6-9.601
                 C19.6,4.698,15.301,0.4,10,0.4z M10,17.599c-4.197,0-7.6-3.402-7.6-7.6S5.802,2.4,10,2.4c4.197,0,7.601,3.402,7.601,7.6
                 S14.197,17.599,10,17.599z"
                  />
                </svg>
              )}
              Nouvelle adresse !
            </button>
            <span className="text-green-600 italic text-xs">
              {errors && errors.new_address ? errors.new_address : ""}
            </span>
          </div>
          {addressType === "new-address" && (
            <div>
              <br />
              <hr />
              <div className="py-1 text-center">
                <input
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  id="inline-full-name"
                  type="text"
                  placeholder="Entrez l'adresse à laquelle vous souhaitez être livré !"
                  onChange={e => setAddress(e.target.value)}
                />
              </div>
              <br />
              <hr />
            </div>
          )}
          {addresses.map((item, key) => (
            <div className="py-1 text-center" key={key}>
              <button
                onClick={() => {
                  setAddressType("existing-address")
                  setAddress(item)
                }}
                className={
                  address && item.id === address.id
                    ? "flex bg-blue-500 border-blue-500 hover:bg-blue-600 hover:border-blue-600 text-white py-2 px-2 rounded w-full"
                    : "flex bg-gray-100 border-gray-100 hover:bg-gray-300 hover:border-gray-300 py-2 px-2 rounded w-full"
                }
              >
                {address && address.id === item.id ? (
                  <svg
                    version="1.1"
                    id="Circled_minus"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 20 20"
                    enableBackground="new 0 0 20 20"
                    className="h-6 w-6 fill-current mx-1"
                  >
                    <path
                      d="M10,1.6c-4.639,0-8.4,3.761-8.4,8.4c0,4.639,3.761,8.4,8.4,8.4s8.4-3.761,8.4-8.4C18.4,5.361,14.639,1.6,10,1.6z
                  M14.789,13.061l-1.729,1.729L10,11.729l-3.061,3.06l-1.729-1.729L8.272,10L5.211,6.939L6.94,5.211L10,8.271l3.061-3.061
                 l1.729,1.729L11.728,10L14.789,13.061z"
                    />
                  </svg>
                ) : (
                  <svg
                    version="1.1"
                    id="Circle"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 20 20"
                    enableBackground="new 0 0 20 20"
                    className="h-6 w-6 fill-current mx-1"
                  >
                    <path
                      d="M10,0.4C4.698,0.4,0.4,4.698,0.4,10C0.4,15.302,4.698,19.6,10,19.6c5.301,0,9.6-4.298,9.6-9.601
               C19.6,4.698,15.301,0.4,10,0.4z M10,17.599c-4.197,0-7.6-3.402-7.6-7.6S5.802,2.4,10,2.4c4.197,0,7.601,3.402,7.601,7.6
               S14.197,17.599,10,17.599z"
                    />
                  </svg>
                )}
                {item.content}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-wrap container mx-auto py-6">
        <div className="text-xl py-2">Méthode de paiement</div>
        <div className="w-full text-sm">
          <span className="text-green-600 italic text-xs">
            {errors && errors.payment_method_id ? errors.payment_method_id : ""}
          </span>
          {paymentMethods.map((pm, key) => (
            <div className="py-1 text-center" key={key}>
              <button
                onClick={() => handlePaymentMethod(pm)}
                className={
                  pm.id === paymentMethod.id
                    ? "flex bg-blue-500 border-blue-500 hover:bg-blue-600 hover:border-blue-600 text-white py-2 px-2 rounded w-full"
                    : "flex bg-gray-100 border-gray-100 hover:bg-gray-300 hover:border-gray-300 py-2 px-2 rounded w-full"
                }
              >
                {pm.id === paymentMethod.id ? (
                  <svg
                    version="1.1"
                    id="Circled_minus"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 20 20"
                    enableBackground="new 0 0 20 20"
                    className="h-6 w-6 fill-current mx-1"
                  >
                    <path
                      d="M10,1.6c-4.639,0-8.4,3.761-8.4,8.4c0,4.639,3.761,8.4,8.4,8.4s8.4-3.761,8.4-8.4C18.4,5.361,14.639,1.6,10,1.6z
                  M14.789,13.061l-1.729,1.729L10,11.729l-3.061,3.06l-1.729-1.729L8.272,10L5.211,6.939L6.94,5.211L10,8.271l3.061-3.061
                 l1.729,1.729L11.728,10L14.789,13.061z"
                    />
                  </svg>
                ) : (
                  <svg
                    version="1.1"
                    id="Circle"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 20 20"
                    enableBackground="new 0 0 20 20"
                    className="h-6 w-6 fill-current mx-1"
                  >
                    <path
                      d="M10,0.4C4.698,0.4,0.4,4.698,0.4,10C0.4,15.302,4.698,19.6,10,19.6c5.301,0,9.6-4.298,9.6-9.601
               C19.6,4.698,15.301,0.4,10,0.4z M10,17.599c-4.197,0-7.6-3.402-7.6-7.6S5.802,2.4,10,2.4c4.197,0,7.601,3.402,7.601,7.6
               S14.197,17.599,10,17.599z"
                    />
                  </svg>
                )}
                {pm.name}
              </button>
              <div className="flex flex-wrap py-2">
                <div className="w-1/6"></div>
                {pm.id === paymentMethod.id &&
                  paymentMethod !== "" &&
                  paymentMethod.children &&
                  paymentMethod.children.map(child => {
                    return (
                      <div className="w-1/6 mr-2">
                        <button
                          onClick={() => setPaymentMethodChild(child)}
                          className={
                            child.id === paymentMethodChild.id
                              ? "flex bg-blue-500 border-blue-500 hover:bg-blue-600 hover:border-blue-600 text-white py-2 px-2 rounded w-full"
                              : "flex bg-gray-100 border-gray-100 hover:bg-gray-300 hover:border-gray-300 py-2 px-2 rounded w-full"
                          }
                        >
                          <div className="flex-col mx-auto">
                            <img
                              src={BASE_URL + "/storage/" + child.logo}
                              alt=""
                              srcset=""
                              className="w-auto h-16 mx-auto"
                            />
                            {child.name}
                          </div>
                        </button>
                      </div>
                    )
                  })}
                <div className="w-1/6"></div>
              </div>
              {paymentMethodChild !== "" &&
                pm.id === paymentMethod.id &&
                paymentMethod.children && (
                  <div className="flex flex-wrap py-2">
                    <div className="w-full mx-2">
                      {paymentMethodChild.description.replace(
                        "%total_price%",
                        Number(subTotalAmount + shippingAmount) + " FCFA"
                      )}
                    </div>
                    <div className="w-full mx-2">
                      <div>
                        <br />
                        <hr />
                        <div>
                          Veuillez s'il vous plait renseigner la référence du
                          dépôt.
                        </div>
                        <div className="py-1 text-center">
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                            id="inline-full-name"
                            type="text"
                            placeholder="Entrez la référence du dépôt"
                            onChange={e => setPaymentRef(e.target.value)}
                          />
                        </div>
                        <br />
                        <hr />
                      </div>
                    </div>
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-wrap py-6 container mx-auto">
        <div className="text-xl py-2">Mes vêtements</div>
        <div className="flex flex-wrap text-sm">
          <hr />
          <table className="w-full table-fixed text-sm">
            <thead>
              <tr>
                <th className="w-1/4 px-4 py-2 text-center"></th>
                <th className="w-1/3 px-4 py-2 text-center">Produit</th>
                <th className="w-1/4 px-4 py-2 text-center">Prix unitaire</th>
                <th className="w-1/4 px-4 py-2 text-center">Quantité</th>
                <th className="w-1/4 px-4 py-2 text-center">Total</th>
              </tr>
            </thead>
            <tbody>
              {cartProducts.map((product, key) => (
                <tr key={key}>
                  <td className="border px-4 py-2 text-center">
                    <img
                      src={BASE_URL + "/storage/" + product.cover}
                      className="w-16 mx-auto"
                      alt=""
                      srcSet=""
                    />
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {product.name}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {product.selling_price + " FCFA"}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <span className="px-2 text-center">{product.quantity}</span>
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {product.selling_price * product.quantity + " FCFA"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="w-1/2"></div>
          <div className="w-full md:w-1/2 mx-2 md:mx-0">
            <div className="flex flex-wrap text-sm py-1">
              <span className="w-1/2 text-left">Sous total</span>
              <span className="w-1/2 text-right">{subTotalAmount} FCFA</span>
            </div>
            <hr />
            <div className="flex flex-wrap text-sm py-1">
              <span className="w-1/2 text-left">Frais de Livraison</span>
              <span className="w-1/2 text-right">{shippingAmount} FCFA</span>
            </div>
            <hr />
            <div className="flex flex-wrap text-base py-2">
              <span className="w-1/2 font-bold text-left">Total</span>
              <span className="w-1/2 font-bold text-right">
                {shippingAmount + subTotalAmount} FCFA
              </span>
            </div>
            <hr />
            <div className="py-2 text-center flex flex-wrap justify-center -mx-2 md:mx-0">
              <div className="w-1/2 md:w-1/3 px-2 md:px-0">
                <button
                  onClick={() => navigate("/cart")}
                  className="w-full py-2 px-2 rounded-full text-white bg-green-500 border-green-500 hover:bg-green-700 hover:border-red-900 text-xs md:text-sm"
                >
                  Revenir au panier
                </button>
              </div>
              <div className="hidden md:block md:w-1/3"></div>
              <div className="w-1/2 md:w-1/3 px-2 md:px-0">
                <button
                  onClick={handleStoreOrder}
                  className="w-full py-2 px-2 rounded-full text-white bg-blue-600 border-blue-600 hover:bg-blue-900 hover:border-blue-900 text-xs md:text-sm"
                >
                  Valider ma commande
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    addresses: state.address.addresses,
    isAddressesLoading: state.address.isLoading,
    paymentMethods: state.paymentMethod.paymentMethods,
    subTotalAmount: state.product.subTotalAmount,
    shippingAmount: state.product.shippingAmount,
    cartProducts: state.product.cart,
    isAuthenticated: state.auth.isAuthenticated,
    errors: state.order.errors,
  }
}

const mapDispatchToProps = {
  getAddresses,
  getPaymentMethods,
  storeOrder,
  emptyCart,
  loadUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
