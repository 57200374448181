import axios from "axios"

import {
  GET_PAYMENT_METHODS_LOADING,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAIL,
} from "./types"
import { BASE_URL } from "../../utils/urls"

export const getPaymentMethods = () => (dispatch, getState) => {
  dispatch({ type: GET_PAYMENT_METHODS_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/api/payment-methods-with-children")
      .then(response => {
        resolve(
          dispatch({
            type: GET_PAYMENT_METHODS_SUCCESS,
            payload: response.data,
          })
        )
      })
      .catch(error => {
        dispatch({ type: GET_PAYMENT_METHODS_FAIL })
        if (error.response) {
          console.log(error.response.data)
        } else {
          console.log(error)
        }
        reject(error.response.data)
      })
  })
}
